<template>
    <!-- The rest of your app code -->

    <div id="app" class="h-100" :class="[skinClasses]">
      
        <b-overlay
            :show="loading"
            rounded="sm"
            variant="white"
            :opacity="0.9"
            style="height: 100vh"
            id="loading-overlay"
        >
            <template v-if="loading" #overlay>
                <center>
                    <!-- <b-spinner variant="primary" style="margin-bottom: 16px" /> -->
                    <font-awesome-icon
                        icon="fa-duotone fa-rotate"
                        class="fa-loader"
                    />
                </center>
                <p id="loading-label">
                    Switching to secure <b>{{ siteName }}</b> site container ...
                </p>
            </template>

            <component :is="layout">
                <router-view />
            </component>
        </b-overlay>
        <scroll-to-top v-if="enableScrollToTop" />
    </div>
</template>

<script>
import ScrollToTop from "@core/components/scroll-to-top/ScrollToTop.vue";

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import EventBus from "@/event-bus.js";
import { getUserRole } from "@/auth/utils";
import { BAlert, BSpinner, BOverlay } from "bootstrap-vue";

import { useWindowSize, useCssVar } from "@vueuse/core";
import { getUserId } from "@/auth/utils";
import SET_LOGIN_STATUS from "@/graphql/profile/mutation/SetLoginStatus.gql";
import CHANGE_LOGIN_STATUS from "@/graphql/profile/mutation/ChangeUserStatus.gql";
import { Auth } from "aws-amplify";
import jwtDecode from "jwt-decode";
import store from "@/store";
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import { getUserName, getUserEmail, getCrispId } from "@/auth/utils";
let myInterval;
const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
    import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

export default {
    components: {
        BAlert,
        BSpinner,
        BOverlay,
        // Layouts
        LayoutHorizontal,
        LayoutVertical,
        LayoutFull,
        BButton,
        ScrollToTop,
    },
    directives: {
        Ripple,
    },
    // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
    // Currently, router.currentRoute is not reactive and doesn't trigger any change
    computed: {
        layout() {
            if (this.$route.meta.layout === "full") return "layout-full";
            return `layout-${this.contentLayoutType}`;
        },
        contentLayoutType() {
            return this.$store.state.appConfig.layout.type;
        },
    },
    data() {
        return {
            loading: false,
            siteName: "",
        };
    },
    created() {
        let token = localStorage.getItem("JoT");
        if (token) {
            let isTokenExpired = this.isJwtExpired(token);
            if (isTokenExpired) {
                this.$swal({
                    title: `Welcome Back`,
                    icon: "success",
                    html: "Restoring the Current Session",
                    timer: 6000,
                    confirmButtonColor: "#00A0F2",
                    showConfirmButton: false,
                });
                Auth.currentSession().then((data) => {
                    console.log("new token acquired");
                    token = data.idToken.jwtToken;
                    localStorage.setItem("JoT", JSON.stringify(token));
                    location.reload();
                });
            }
        }
        let that = this;
        this.updateStatus();
        myInterval = setInterval(async () => {
            await that.updateStatus();
        }, 60000);
        this.updateOnAway();
    },
    methods: {
        isJwtExpired(token) {
            if (typeof token !== "string" || !token) return "anonymous";
            let isJwtExpired = false;
            const { exp } = jwtDecode(token);
            const currentTime = new Date().getTime() / 1000;
            if (currentTime > exp) isJwtExpired = true;
            return isJwtExpired;
        },
        async updateStatus() {
            if (localStorage.getItem("JoT")) {
                let data = await this.$apollo.mutate({
                    mutation: SET_LOGIN_STATUS,
                    variables: {
                        user_id: getUserId(),
                        login_status: 1,
                        last_seen: new Date(),
                    },
                });
            }
        },
        updateOnAway() {
            let that = this;
            document.onvisibilitychange = async (e) => {
                if (localStorage.getItem("JoT")) {
                    if (document.visibilityState === "hidden") {
                        clearInterval(myInterval);
                        let data = await that.$apollo.mutate({
                            mutation: CHANGE_LOGIN_STATUS,
                            variables: {
                                user_id: getUserId(),
                                login_status: 2,
                            },
                        });
                    } else {
                        await that.updateStatus();
                        myInterval = setInterval(async () => {
                            await that.updateStatus();
                        }, 60000);
                    }
                }
            };
        },
    },
    mounted() {
        EventBus.$on("start-site-switch", (siteObject) => {
            this.siteName = siteObject.site.site_name;
            this.loading = true;
        });
        EventBus.$on("end-site-switch", () => {
            this.loading = false;
            this.siteName = "";
        });

        Auth.currentAuthenticatedUser().then((user) => {
            if (user) {
                let crispchat = document.getElementById("crisp-chatbox");
                let crispicon = document.querySelector(
                    "#crisp-chatbox > div > a > span.cc-7doi.cc-1ada > span > span.cc-15mo"
                );
                // crispchat.style.visibility = "visible";
                // crispicon.style.visibility = "visible";
            }
        });
    },
    beforeCreate() {
        // Set colors in theme
        const colors = [
            "primary",
            "secondary",
            "success",
            "info",
            "warning",
            "danger",
            "light",
            "dark",
        ];

        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = colors.length; i < len; i++) {
            $themeColors[colors[i]] = useCssVar(
                `--${colors[i]}`,
                document.documentElement
            ).value.trim();
        }

        // Set Theme Breakpoints
        const breakpoints = ["xs", "sm", "md", "lg", "xl"];

        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = breakpoints.length; i < len; i++) {
            $themeBreakpoints[breakpoints[i]] = Number(
                useCssVar(
                    `--breakpoint-${breakpoints[i]}`,
                    document.documentElement
                ).value.slice(0, -2)
            );
        }

        // Set RTL
        const { isRTL } = $themeConfig.layout;
        document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
    },
    setup() {
        const { skin, skinClasses } = useAppConfig();
        const { enableScrollToTop } = $themeConfig.layout;

        // If skin is dark when initialized => Add class to body
        if (skin.value === "dark") document.body.classList.add("dark-layout");

        // Provide toast for Composition API usage
        // This for those apps/components which uses composition API
        // Demos will still use Options API for ease
        provideToast({
            hideProgressBar: true,
            closeOnClick: false,
            closeButton: false,
            icon: false,
            timeout: 3000,
            transition: "Vue-Toastification__fade",
        });

        // Set Window Width in store
        store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
        const { width: windowWidth } = useWindowSize();
        watch(windowWidth, (val) => {
            store.commit("app/UPDATE_WINDOW_WIDTH", val);
        });

        return {
            getUserRole,
            skinClasses,
            enableScrollToTop,
        };
    },
};
</script>

<style lang="scss">
.Vue-Toastification__toast--default.custom {
    background: white;
    border-left: 16px solid #00a0f3;
    color: #00a0f3;
    .Vue-Toastification__progress-bar {
        background-color: #00a0f333 !important;
    }
}

.Vue-Toastification__toast--info.custom {
    background: white;
    border-left: 16px solid #00a0f3;
    color: #00a0f3;
    .Vue-Toastification__progress-bar {
        background-color: #00a0f333 !important;
    }
}
.Vue-Toastification__toast--success.custom {
    background: white;
    padding-top:16px;
    padding-bottom:16px;
    //border-top:16px solid #3fcb7a;
    color: #3fcb7a;
    .Vue-Toastification__progress-bar {
        background-color: #3fcb7a33 !important;
    }
}

.Vue-Toastification__toast {
    padding-left: 20px !important;
    padding-right: 20px !important;
    max-width: 400px !important;
}

.Vue-Toastification__close-button {
    color: #052d41 !important;
    margin-right: 20px !important;
}

.Vue-Toastification__toast--warning.custom {
    background: white;
    padding-top:16px;
    padding-bottom:16px;
    color: #ffbe4e;
    .Vue-Toastification__progress-bar {
        background-color: #ffbe4e33 !important;
    }
}

.Vue-Toastification__toast--error.custom {
    background: white;
    padding-top:16px;
    padding-bottom:16px;
    color: #ff695b;
    .Vue-Toastification__progress-bar {
        background-color: #ff695b33 !important;
    }
}

.fa-loader {
    font-size: 28px;
    --fa-primary-color: #03a0f3;
    --fa-secondary-color: #03a0f3;
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    margin-bottom: 12px;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
