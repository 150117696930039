import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate, hasPermissionToNavigate, hasPermissiontoNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getHomeRouteForLoggedInUser, getUserRole } from '@/auth/utils'
import dashboard from './routes/dashboard'
import pages from './routes/pages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'qa-review' } },
    ...dashboard,
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    
    // Redirect to login if not logged in
    if (!isLoggedIn){
      sessionStorage.setItem('redirectPath', to.path)
      return next({ name: 'auth-login'})
    } 
    
    // If logged in => not authorized
    //return next({ name: 'misc-not-authorized' })
    return next({ name: 'auth-login' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const role = getUserRole()
    next(getHomeRouteForLoggedInUser(role ? role : null))
  }

  if(isLoggedIn){

  try{
    let tempRole = localStorage.getItem('Role')
    const role = tempRole  
    var x = hasPermissionToNavigate(to,role)
    if(x){
      return next()
    }else{
      return next('/')
    }
  }catch(err){
    console.log(err)
    return next()
  }

  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
