export default [ 

  {
    path: "/qa-review",
    name: "qa-review",
    component: () => import("@/views/qa-review/QaReview.vue"),
    meta: {
      permission: ["admin", "Staff", "SiteAdmin", "CompanyAdmin"],
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/auth/Register.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/verify-account",
    name: "verify-account",
    props: true,
    component: () => import("@/views/auth/VerifyAccount.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/pre-login",
    name: "pre-login",
    component: () => import("@/views/auth/PreLogin.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/mfa",
    name: "auth-mfa",
    component: () => import("@/views/auth/Mfa.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/forgot-password",
    name: "auth-forgot-password",
    component: () => import("@/views/auth/ForgotPassword.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/reset-password",
    name: "auth-reset-password",
    props: true,
    component: () => import("@/views/auth/ResetPassword.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  
];
