import useJwt from "@/auth/jwt/useJwt";
// because its a default export we can directly make an alisa like i've done below
import jwtHelper from "jsonwebtoken";
import database from "mime-db";
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return (
    localStorage.getItem("JoT") && localStorage.getItem("refreshToken")
  );
};

export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return "/";
  if (userRole === "client") return { name: "access-control" };
  return { name: "auth-login" };
};

export const getUserRole = () => {
  const data = localStorage.getItem("JoT");
  if(data){
    // starnge that escape sequence data gets added hence the need to use slice command
    const payload = jwtHelper.decode(data.slice(1, data.length - 1));
    return JSON.parse(payload["https://hasura.io/jwt/claims"])[
      "x-hasura-default-role"
    ];
  }
  else{
    return null
  }
};

export const getUserEmail = () => {
  const data = localStorage.getItem("JoT");
  // starnge that escape sequence data gets added hence the need to use slice command
  const payload = jwtHelper.decode(data.slice(1, data.length - 1));
  return payload["email"]
};

export const getCrispId = () => {
  const data = localStorage.getItem("JoT");
  // starnge that escape sequence data gets added hence the need to use slice command
  const payload = jwtHelper.decode(data.slice(1, data.length - 1));
  return JSON.parse(payload["https://hasura.io/jwt/claims"])[
    "x-hasura-crisp-id"
  ];
};

export const getUserName = () => {
  const data = localStorage.getItem("JoT");
  // starnge that escape sequence data gets added hence the need to use slice command
  const payload = jwtHelper.decode(data.slice(1, data.length - 1));
  let first_name=JSON.parse(payload["https://hasura.io/jwt/claims"])[
    "x-hasura-first-name"
  ];
  let last_name=JSON.parse(payload["https://hasura.io/jwt/claims"])[
    "x-hasura-last-name"
  ];
  return first_name+" "+last_name
};

export const getUserProfileImage = () => {
  const data = localStorage.getItem("JoT");
  // starnge that escape sequence data gets added hence the need to use slice command
  const payload = jwtHelper.decode(data.slice(1, data.length - 1));
  return JSON.parse(payload["https://hasura.io/jwt/claims"])[
    "x-hasura-profile-url"
  ];
};

export const getUserDeveloperMode = () => {
  const data = localStorage.getItem("JoT");
  // starnge that escape sequence data gets added hence the need to use slice command
  const payload = jwtHelper.decode(data.slice(1, data.length - 1));
  return JSON.parse(payload["https://hasura.io/jwt/claims"])[
    "x-hasura-developer-mode"
  ];
};

export const getMaxCameraIntegrations = () => {
  const data = localStorage.getItem("JoT");
  // starnge that escape sequence data gets added hence the need to use slice command
  const payload = jwtHelper.decode(data.slice(1, data.length - 1));
  return JSON.parse(payload["https://hasura.io/jwt/claims"])[
    "x-hasura-max-site-cam-integration"
  ];
};


// probably regex would be better
export const extractRole = (data) => {
  let x = data["https://hasura.io/jwt/claims"];
  x = x
    .slice(1, x.length - 1)
    .split(",")[1]
    .split(":")[1]
    .replace("\"","")
    .replace("\"","");
  return x;
};

export const getUserSiteId = () => {
  const data = localStorage.getItem("JoT");
  const payload = jwtHelper.decode(data.slice(1, data.length - 1));
  return JSON.parse(payload["https://hasura.io/jwt/claims"])[
    "x-hasura-site-id"
  ];
};

export const getUserId = () => {
  const data = localStorage.getItem("JoT");
  const payload = jwtHelper.decode(data.slice(1, data.length - 1));
  return JSON.parse(payload["https://hasura.io/jwt/claims"])[
    "x-hasura-user-id"
  ];
};


export const getUserCompanyId = () => {
  const data = localStorage.getItem("JoT");
  const payload = jwtHelper.decode(data.slice(1, data.length - 1));
  return JSON.parse(payload["https://hasura.io/jwt/claims"])[
    "x-hasura-company-id"
  ];
};

export const hasuraClaimExtractor = (claim) => {
  const data = localStorage.getItem("JoT");
  const payload = jwtHelper.decode(data.slice(1, data.length - 1));
  return JSON.parse(payload["https://hasura.io/jwt/claims"])[
    claim
  ];
};
