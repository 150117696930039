import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import { default as msalPlugin } from "vue-msal-browser";
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import VueApollo from 'vue-apollo';
import {ColorPicker, ColorPanel} from 'one-colorpicker'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
//import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
// ciaran I am disabling this
// import '@/libs/tour'

// Axios Mock Adapter
// unnecssary remove it after end of June '22
import '@/@fake-db/db'
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import awsconfig from "./aws-exports";
import { Logger } from 'aws-amplify';
// Use this only for debugging of the aws-amplify plugin
// Amplify.Logger.LOG_LEVEL = 'DEBUG';

// Allows us to know if app is in local Dev Mode and make appropriate setting shifts
const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const redirectSignInURL = isLocalhost?'http://localhost:8080/pre-login':process.env.VUE_APP_GOOGLE_REDIRECT_SIGN_IN_URL
const redirectSignOutURL = isLocalhost? 'http://localhost:8080/':process.env.VUE_APP_GOOGLE_REDIRECT_SIGN_OUT_URL

// detach user-pool from amplify and apply directly
// We store this in Amplify Env and Have the build script inject it to production .env
Amplify.configure(awsconfig);

Vue.use(msalPlugin, {
  auth: {
    clientId: process.env.VUE_APP_AZURE_CLIENT_ID,
    redirectUri: process.env.VUE_APP_AZURE_REDIRECT_URI,
    authority: 'https://login.microsoftonline.com/common/'
  }
});
import posthog from 'posthog-js'
posthog.init('phc_j0xJKIacnLSvofl2ss2eXH55pEjH34J34lD5JpJsZ2A', { api_host: 'https://eu.posthog.com' })


Vue.use(ColorPanel)
Vue.use(ColorPicker)

// You can get the current config object
// const currentConfig = Auth.configure();
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

import { createProvider } from './vue-apollo'

Amplify.configure(aws_exports);
applyPolyfills().then(() => {
  defineCustomElements(window);
});

// BSV Plugin Registration
//Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
  transition: "Vue-Toastification__fade",
  maxToasts: 20,
  newestOnTop: true,
  position: "top-right",
  toastClassName: 'custom',
  bodyClassName: ['custom'],
  position: "top-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  closeButton: "button",
  icon: true,
  rtl: false,
};


Vue.use(Toast, options);

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
import "@/assets/tooltip.css";

import VCalendar from 'v-calendar';

// Use v-calendar & v-date-picker components
Vue.use(VCalendar);

import VTooltip from "v-tooltip";
//import "./assets/tooltip.css";

Vue.use(VTooltip);
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.config.productionTip = false
Vue.use(VueApollo);

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faLocationDot, faCircleQuestion, faBell as faSolidBell, faLock, faMagnifyingGlass, faChevronUp, faTrash, faShareFromSquare, faPenToSquare, faComments as faCommentsSolid, faUser, faBolt,faTags, faChartSimple, faFileLines, faHouse, faPenRuler, faSquareCheck, faVideo, faWindowRestore, faGear, faPlug, faBook, faRightFromBracket, faCircleMinus, faBookOpen, faCircleCheck, faClipboardCheck, faClock, faDiagramProject, faLink, faEllipsisVertical, faListCheck, faCheckDouble, faPaperPlane, faCircleXmark, faCircleInfo, faUpload, faCaretRight, faCaretLeft, faCheck, faX, faFlag, faQuestion, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faCalendar, faComments, faEyeSlash, faEye } from '@fortawesome/free-regular-svg-icons'
import { faRotate, faCirclePlay } from '@fortawesome/pro-duotone-svg-icons'
import { faCameraViewfinder, faChevronsLeft, faChevronsRight, faPlusLarge, faSparkles, faXmarkLarge } from '@fortawesome/pro-solid-svg-icons'
import { faCommentPlus, faRectangleHistoryCirclePlus, faImage, faTags as faTagsRegular, faVideo as faVideoRegular, faDiagramProject as faDiagramProjectRegular, faUsers, faBell } from '@fortawesome/pro-regular-svg-icons'
/* add icons to the library */
library.add( faChevronsLeft, faChevronsRight, faArrowRight, faQuestion, faFlag, faCheck, faX, faLocationDot, faCircleQuestion, faCaretRight, faCaretLeft, faPlusLarge, faSparkles, faCameraViewfinder, faUpload, faCircleInfo, faSolidBell, faLock, faCirclePlay, faXmarkLarge, faCircleXmark, faPaperPlane, faRotate, faEye, faEyeSlash, faCheckDouble, faClipboardCheck, faListCheck, faMagnifyingGlass, faBell, faChevronUp, faTrash, faEllipsisVertical, faShareFromSquare, faCommentPlus, faRectangleHistoryCirclePlus, faPenToSquare, faComments, faCommentsSolid, faTags, faLink, faImage, faTagsRegular, faVideoRegular, faDiagramProjectRegular, faDiagramProject, faUsers, faCalendar, faClock, faCircleCheck, faBookOpen, faCircleMinus, faRightFromBracket, faPlug, faBook, faGear, faUser, faHouse, faWindowRestore, faSquareCheck, faFileLines, faBolt, faChartSimple, faVideo, faPenRuler )

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  router,
  store,
  i18n,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
if(isLocalhost){
  Vue.config.devtools = true;
}
