import Vue from "vue";
import VueApollo from "vue-apollo";
import { Auth } from "aws-amplify";
import { createApolloClient, restartWebsockets } from "vue-cli-plugin-apollo/graphql-client";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { split, ApolloLink } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
// This is for setting headers into our request
import { initialAbility } from "@/libs/acl/config";
import { onError } from "@apollo/client/link/error";
import { SubscriptionClient } from "subscriptions-transport-ws";

// This serves dual purpose first checks if jwt expired else if user is anon
var jwtDecode = require("jwt-decode");
const isJwtExpired = (token) => {
  if (typeof token !== "string" || !token) return "anonymous";
  let isJwtExpired = false;
  const { exp } = jwtDecode(token);
  const currentTime = new Date().getTime() / 1000;
  if (currentTime > exp) isJwtExpired = true;
  return isJwtExpired;
};

Vue.use(VueApollo);
export let loggedOnce = { state: false };
const httpEndpoint = process.env.VUE_APP_API_HTTP_URL;
const wsEndpoint = process.env.VUE_APP_API_WSS_URL;
let baseLink = new ApolloLink((operation, forward) => {
  let token = localStorage.getItem("JoT");
  let isTokenExpired = isJwtExpired(token);
  if (isTokenExpired === true) {
    Auth.currentSession()
      .then((data) => {
        token = data.idToken.jwtToken;
        localStorage.setItem("JoT", JSON.stringify(token));
        const headerData = token ? { authorization: `Bearer ${token}` } : {};
        operation.setContext({ headers: headerData });
        return forward(operation);
      })
      .catch((e) => {
        console.log("error inside apollo link");
        console.log("error", e);
        if (e.code == "NotAuthorizedException") {
          localStorage.clear();
          location.reload();
        }
      });
  } else if (isTokenExpired === false) {
    token = JSON.parse(token);
    const headerData = token ? { authorization: `Bearer ${token}` } : {};
    operation.setContext({ headers: headerData });
    return forward(operation);
  } else if (isTokenExpired === "anonymous") {
    // ensures we dont send any context if token is empty allowing for anonymous access
    return forward(operation);
  }
});
let observerLink = new ApolloLink((operation, forward) => {
  return forward(operation);
});
const baseAndObserverLink = baseLink.concat(observerLink);

const errorLink = onError((error) => {
  if (error.graphQLErrors) {
    // console.log("GraphQL Error detected")
    if (error.graphQLErrors.extensions && error.graphQLErrors.extensions.code && error.graphQLErrors.extensions.code === "invalid-jwt") {
      // console.log("JWT EXPIRED")
    }
  } else if (error.networkError && error.networkError.extensions && error.networkError.extensions.code && error.networkError.extensions.code === "start-failed") {
    // console.log("GraphQL Error detected type 2")
    // 	console.log("Unable to Connect")
  } else if (error.networkError && error.networkError.extensions && error.networkError.extensions.code && error.networkError.extensions.code === "validation-failed") {
    // console.log("GraphQL Error detected type 3")
    // console.log("Validation Error")
  } else {
    console.log(JSON.stringify(error));
  }
});
// Suhanis solution to websocket restart
var wsLink = new WebSocketLink(
  new SubscriptionClient(wsEndpoint, {
    reconnect: false,
    lazy: true,
    timeout: 300000,
    connectionParams: async () => {
      const token = JSON.parse(localStorage.getItem("JoT"));
      return {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      };
    },
  })
);

export var filesRoot = process.env.VUE_APP_FILES_ROOT || httpEndpoint.substr(0, httpEndpoint.indexOf("/graphql"));

Vue.prototype.$filesRoot = filesRoot;

var httpLink = new HttpLink({ uri: httpEndpoint });

var splitLLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  httpLink
);

export var defaultOptions = {
  httpEndpoint,
  wsEndpoint,
  persisting: false,
  websocketsOnly: true,
  ssr: false,
  link: baseAndObserverLink.concat(errorLink).concat(splitLLink),
};

export const { apolloClient, wsClient } = createApolloClient({
  ...defaultOptions,
});

export function createProvider(options = {}) {
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  });
  apolloClient.wsClient = wsClient;
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    async errorHandler(error) {
      console.log("async handler");
      console.log(error);
      if (error.code === "NotAuthorizedException") {
        await AwsSignOut();
      }
    },
  });
  return apolloProvider;
}

async function AwsSignOut() {
  try {
    await Auth.signOut({ global: true });
    localStorage.clear();
    loggedOnce.state = false;
    this.$ability.update(initialAbility);
    this.$router.push({ name: "auth-login" });
  } catch (error) {
    localStorage.clear();
    loggedOnce.state = false;
    this.$ability.update(initialAbility);
    this.$router.push({ name: "auth-login" });
  }
}
