import mock from "@/@fake-db/mock";
// import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  tasks: [
    {
      id: 1,
      title: "Social Distancing Violation - West Entrance #002",
      dueDate: "2020-11-25",
      description:
        "<p></p>",
      assignee: {
        fullName: "Jacob Ramirez",
        avatar: require("@/assets/images/avatars/12.png"),
      },
      tags: ["update"],
      
      isCompleted: false,
      isDeleted: false,
      isImportant: false,
    },
    {
      id: 2,
      title: "Social Distancing Violation - West Entrance #015",
      dueDate: "2020-12-14",
      description:
        "<p></p>",
      assignee: {
        fullName: "Andrew Anderson",
        avatar: "",
      },
      tags: [, "medium"],
      

      isCompleted: true,
      isDeleted: false,
      isImportant: false,
    },
    {
      id: 3,
      title: "Forklift Speed Limit Violation - Camera West Gate #003",
      dueDate: "2020-11-25",
      description:
        "<p></p>",
      assignee: {
        fullName: "Benjamin Jacobs",
        avatar: "",
      },
      tags: ["high"],
      

      isCompleted: false,
      isDeleted: false,
      isImportant: false,
    },
    {
      id: 4,
      title: "Exclusion Zone Violation - Camera Isle 72 #029",
      dueDate: "2020-11-20",
      description:
        "<p></p>",
      assignee: {
        fullName: "Curtis Schmidt",
        avatar: require("@/assets/images/avatars/9.png"),
      },
      tags: ["medium"],
      

      isCompleted: false,
      isDeleted: false,
      isImportant: true,
    },
    {
      id: 5,
      title:
        "Exclusion Zone - Camera Stack A & B #034",
      dueDate: "2020-12-06",
      description:
        "<p></p>",
      assignee: {
        fullName: "Katherine Perkins",
        avatar: require("@/assets/images/avatars/9.png"),
      },
      tags: ["medium"],
      

      isCompleted: true,
      isDeleted: false,
      isImportant: true,
    },
    {
      id: 10,
      title: "Vehicle and Person Proximity Violation - Camera Truck Yard #021",
      dueDate: "2020-12-09",
      description:
        "<p></p>",
      assignee: {
        fullName: "Karen Carrillo",
        avatar: "",
      },
      tags: ["low"],
      isCompleted: true,
      isDeleted: false,
      isImportant: false,
    },
    {
      id: 11,
      title: "Vehicle Speed Violation - Camera East Terminal Corner",
      dueDate: "2020-12-01",
      description:
        "<p></p>",
      assignee: {
        fullName: "Timothy Brewer",
        avatar: require("@/assets/images/avatars/1.png"),
      },
      tags: ["low"],

      isCompleted: true,
      isDeleted: false,
      isImportant: true,
    },
    {
      id: 12,
      title: "Forklift Exclusion Zone Violation - Camera Unit 2A #011",
      dueDate: "2020-12-09",
      description:
        "<p></p>",
      assignee: {
        fullName: "Brian Barry",
        avatar: "",
      },
      tags: [],

      isCompleted: false,
      isDeleted: false,
      isImportant: false,
    },
    {
      id: 18,
      title: "Speed Limit Violation - Camera Terminal Entrance #001",
      dueDate: "2020-11-18",
      description:
        "<p></p>",
      assignee: {
        fullName: "Danielle Anderson",
        avatar: require("@/assets/images/avatars/12.png"),
      },
      tags: ["low"],
      

      isCompleted: false,
      isDeleted: false,
      isImportant: true,
    },
  ],
};
/* eslint-enable */

// ------------------------------------------------
// GET: Return Tasks
// ------------------------------------------------
mock.onGet("/violations/tasks").reply((config) => {
  // eslint-disable-next-line object-curly-newline
  const { q = "", filter, tag, sortBy: sortByParam = "latest" } = config.params;
  /* eslint-enable */

  // ------------------------------------------------
  // Get Sort by and Sort Direction
  // ------------------------------------------------
  let sortDesc = true;

  const sortBy = (() => {
    if (sortByParam === "title-asc") {
      sortDesc = false;
      return "title";
    }
    if (sortByParam === "title-desc") return "title";
    if (sortByParam === "assignee") {
      sortDesc = false;
      return "assignee";
    }
    if (sortByParam === "due-date") {
      sortDesc = false;
      return "dueDate";
    }
    return "id";
  })();

  // ------------------------------------------------
  // Filtering
  // ------------------------------------------------
  const queryLowered = q.toLowerCase();

  const hasFilter = (task) => {
    if (filter === "important") return task.isImportant && !task.isDeleted;
    if (filter === "completed") return task.isCompleted && !task.isDeleted;
    if (filter === "deleted") return task.isDeleted;
    return !task.isDeleted;
  };
  /* eslint-disable no-confusing-arrow, implicit-arrow-linebreak, arrow-body-style */
  const filteredData = data.tasks.filter((task) => {
    return (
      task.title.toLowerCase().includes(queryLowered) &&
      hasFilter(task) &&
      (tag ? task.tags.includes(tag) : true)
    );
  });
  /* eslint-enable  */

  // ------------------------------------------------
  // Perform sorting
  // ------------------------------------------------
  const sortTasks = (key) => (a, b) => {
    let fieldA;
    let fieldB;

    // If sorting is by dueDate => Convert data to date
    if (key === "dueDate") {
      fieldA = new Date(a[key]);
      fieldB = new Date(b[key]);
      // eslint-disable-next-line brace-style
    }

    // If sorting is by assignee => Use `fullName` of assignee
    else if (key === "assignee") {
      fieldA = a.assignee ? a.assignee.fullName : null;
      fieldB = b.assignee ? b.assignee.fullName : null;
    } else {
      fieldA = a[key];
      fieldB = b[key];
    }

    let comparison = 0;

    if (fieldA === fieldB) {
      comparison = 0;
    } else if (fieldA === null) {
      comparison = 1;
    } else if (fieldB === null) {
      comparison = -1;
    } else if (fieldA > fieldB) {
      comparison = 1;
    } else if (fieldA < fieldB) {
      comparison = -1;
    }

    return comparison;
  };

  // Sort Data
  const sortedData = filteredData.sort(sortTasks(sortBy));
  if (sortDesc) sortedData.reverse();

  return [200, sortedData];
});

// ------------------------------------------------
// POST: Add new task
// ------------------------------------------------
mock.onPost("/violations/tasks").reply((config) => {
  // Get event from post data
  const { task } = JSON.parse(config.data);

  const { length } = data.tasks;
  let lastIndex = 0;
  if (length) {
    lastIndex = data.tasks[length - 1].id;
  }
  task.id = lastIndex + 1;

  data.tasks.push(task);

  return [201, { task }];
});

// ------------------------------------------------
// POST: Update Task
// ------------------------------------------------
mock.onPost(/\/violations\/tasks\/\d+/).reply((config) => {
  const { task: taskData } = JSON.parse(config.data);

  // Convert Id to number
  taskData.id = Number(taskData.id);

  const task = data.tasks.find((e) => e.id === Number(taskData.id));
  Object.assign(task, taskData);

  return [200, { task }];
});

// ------------------------------------------------
// DELETE: Remove Task
// ------------------------------------------------
mock.onDelete(/\/violations\/tasks\/\d+/).reply((config) => {
  // Get task id from URL
  let taskId = config.url.substring(config.url.lastIndexOf("/") + 1);

  // Convert Id to number
  taskId = Number(taskId);

  const task = data.tasks.find((t) => t.id === taskId);
  Object.assign(task, { isDeleted: true });
  return [200];
});
