import ability from './ability'

export const canNavigate = to => to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))

export const _ = undefined

export const hasPermissionToNavigate = (to,role) => {
	try{
		return to.meta.permission.includes(role)
	}catch(err){
		console.log(err)
	}	
}