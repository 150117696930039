import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  invoices: [
    {
      id: 4987,
      dateCreated: '13 Dec 2021',
      period: '13 Dec 2021 - 14 Dec 2021',
      violations: 3,
      client: {
        address: '7777 Mendez Plains',
        company: 'Hall-Robbins PLC',
        companyEmail: 'don85@johnson.com',
        country: 'USA',
        contact: '(616) 865-4180',
        name: 'Jordan Stevenson',
      },
      service: 'Software Development',
      total: 3428,
      avatar: '',
      balance: '$724',
      dueDate: '23 Apr 2021',
    },
    {
      id: 4988,
      dateCreated: '17 Jul 2021',
      period: '13 Dec 2021 - 15 Dec 2021',
      violations: 6,
      client: {
        address: '04033 Wesley Wall Apt. 961',
        company: 'Mccann LLC and Sons',
        companyEmail: 'brenda49@taylor.info',
        country: 'Haiti',
        contact: '(226) 204-8287',
        name: 'Stephanie Burns',
      },
      service: 'UI/UX Design & Development',
      total: 5219,
      avatar: require('@/assets/images/avatars/10-small.png'),
      balance: 0,
      dueDate: '15 Dec 2021',
    },
    {
      id: 4989,
      dateCreated: '19 Oct 2021',
      period: '01 Oct 2021 - 10 Oct 2021',
      violations: 13,
      client: {
        address: '5345 Robert Squares',
        company: 'Leonard-Garcia and Sons',
        companyEmail: 'smithtiffany@powers.com',
        country: 'Denmark',
        contact: '(955) 676-1076',
        name: 'Tony Herrera',
      },
      service: 'Unlimited Extended License',
      total: 3719,
      avatar: require('@/assets/images/avatars/1-small.png'),
      balance: 0,
      dueDate: '03 Nov 2021',
    },
    {
      id: 4990,
      dateCreated: '22 Mar 2021',
      period: '16 Jan 2021 - 21 Oct 2021',
      violations: 28,
      client: {
        address: '19022 Clark Parks Suite 149',
        company: 'Smith, Miller and Henry LLC',
        companyEmail: 'mejiageorge@lee-perez.com',
        country: 'Cambodia',
        contact: '(832) 323-6914',
        name: 'Kevin Patton',
      },
      service: 'Software Development',
      total: 4749,
      avatar: require('@/assets/images/avatars/9-small.png'),
      balance: 0,
      dueDate: '11 Feb 2020',
    },
    {
      id: 4991,
      dateCreated: '08 Feb 2021',
      period: '02 Feb 2021 - 08 Feb 2021',
      violations: 34,
      client: {
        address: '8534 Saunders Hill Apt. 583',
        company: 'Garcia-Cameron and Sons',
        companyEmail: 'brandon07@pierce.com',
        country: 'Martinique',
        contact: '(970) 982-3353',
        name: 'Mrs. Julie Donovan MD',
      },
      service: 'UI/UX Design & Development',
      total: 4056,
      avatar: require('@/assets/images/avatars/10-small.png'),
      balance: '$815',
      dueDate: '30 Jun 2021',
    },
    {
      id: 4992,
      dateCreated: '26 Aug 2021',
      period: '16 Aug 2021 - 24 Aug 2021',
      violations: 2,
      client: {
        address: '661 Perez Run Apt. 778',
        company: 'Burnett-Young PLC',
        companyEmail: 'guerrerobrandy@beasley-harper.com',
        country: 'Botswana',
        contact: '(511) 938-9617',
        name: 'Amanda Phillips',
      },
      service: 'UI/UX Design & Development',
      total: 2771,
      avatar: '',
      balance: 0,
      dueDate: '24 Jun 2021',
    },
    {
      id: 4993,
      dateCreated: '17 Sep 2021',
      period: '16 Sep 2021 - 17 Sep 2021',
      violations: 4,
      client: {
        address: '074 Long Union',
        company: 'Wilson-Lee LLC',
        companyEmail: 'williamshenry@moon-smith.com',
        country: 'Montserrat',
        contact: '(504) 859-2893',
        name: 'Christina Collier',
      },
      service: 'UI/UX Design & Development',
      total: 2713,
      avatar: '',
      balance: '$407',
      dueDate: '22 Nov 2021',
    },
    {
      id: 5076,
      dateCreated: '18 Jan 2021',
      period: '16 Jan 2021 - 18 Jan 2021',
      violations: 10,
      client: {
        address: '074 Long Union',
        company: 'Wilson-Lee LLC',
        companyEmail: 'ciaran@protex.ai',
        country: 'Montserrat',
        contact: '(504) 859-2893',
        name: 'Ciarán O\'Mara',
      },
      service: 'UI/UX Design & Development',
      total: 2713,
      avatar: require('@/assets/images/avatars/13-small.png'),
      balance: '$407',
      dueDate: '22 Nov 2021',
    },
    
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/reporting/invoices').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, sortBy = 'id', sortDesc = false, status = null } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.invoices.filter(
    invoice =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (invoice.client.companyEmail.toLowerCase().includes(queryLowered) ||
        invoice.client.name.toLowerCase().includes(queryLowered)) &&
      invoice.invoiceStatus === (status || invoice.invoiceStatus),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      invoices: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// GET: Return Single Invoice
// ------------------------------------------------
mock.onGet(/\/reporting\/invoices\/\d+/).reply(config => {
  // Get event id from URL
  let invoiceId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  invoiceId = Number(invoiceId)

  const invoiceIndex = data.invoices.findIndex(e => e.id === invoiceId)
  const invoice = data.invoices[invoiceIndex]
  const responseData = {
    invoice,
    paymentDetails: {
      totalDue: '$12,110.55',
      bankName: 'American Bank',
      country: 'United States',
      iban: 'ETD95476213874685',
      swiftCode: 'BR91905',
    },
  }

  if (invoice) return [200, responseData]
  return [404]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/reporting/clients').reply(() => {
  const clients = data.invoices.map(invoice => invoice.client)
  return [200, clients.slice(0, 5)]
})
